import React from "react"
import styled from "@emotion/styled"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Container, Button } from "./componentHelpers"
import BackgroundImg from "gatsby-background-image"
import Img from "gatsby-image"
import { HERO_TEXT, BUTTON_CTA_TEXT } from "../strings"

import { theme } from "../theme"

const StyledHero = styled.section`
  width: 100%;
  position: relative;
`
const BackgroundImage = styled(BackgroundImg)`
  height: 700px;
  position: relative;
  @media (max-width: 767px) {
    height: 400px;
  }
`
const ContentContainer = styled(Container)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 180px 0;
  box-sizing: border-box;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
const Content = styled.div`
  flex-basis: 55%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    padding: 20px;
  }
`
const Image = styled.div`
  flex-basis: 40%;

  @media (max-width: 767px) {
    display: none;
  }
`

const HeroText = styled.h1`
  font-size: 32px;
  color: ${theme.colors.white};
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-family: Tahoma, Geneva, Verdana, sans-serif;
`
const HighlightedHeroText = ({ text }) => {
  const replacedText = text.replace(
    /Canadian/g,
    `<span style="color: ${theme.colors.red}">Canadian</span>`
  )

  return <HeroText dangerouslySetInnerHTML={{ __html: replacedText }} />
}

const BackgroundMask = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Hero = () => {
  const heroImageData = useStaticQuery(
    graphql`
      query {
        lakeImage: file(relativePath: { eq: "lake.JPG" }) {
          childImageSharp {
            fluid(maxWidth: 2000, maxHeight: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        maskImage: file(relativePath: { eq: "mask.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <StyledHero>
      <BackgroundImage fluid={heroImageData.lakeImage.childImageSharp.fluid}>
        <BackgroundMask />
        <ContentContainer>
          <Content>
            <HighlightedHeroText text={HERO_TEXT} />
            <a href="#value-props">
              <Button background={theme.colors.purple}>
                {BUTTON_CTA_TEXT}
              </Button>
            </a>
          </Content>
          <Image>
            <Img fluid={heroImageData.maskImage.childImageSharp.fluid} />
          </Image>
        </ContentContainer>
      </BackgroundImage>
    </StyledHero>
  )
}

export default Hero
