// export const HERO_TEXT = "Helping Canadian businesses source Canadian masks"
// export const BUTTON_CTA_TEXT = "Do your employees need masks to work safely?"
export const HERO_TEXT = "Do your employees need masks to work safely?"
export const BUTTON_CTA_TEXT = "Learn more"

export const HELPING_CANADIANS =
  "Helping Canadian businesses source Canadian masks"
export const THANK_YOU_FL_WORKERS = "Thank you to our frontline workers"
export const DONATE_TEXT =
  "We encourage everyone that is capable of supporting Canada's COVID-19 relief efforts to make a donation."
export const DONATE_CTA = "Donate"

export const MASK_DETAIL_SECTIONS = [
  {
    title: "When to wear",
    description:
      "The government of Canada advises wearing a mask when out in the community as a measure to protect yourself and others – this includes at the workplace.",
  },
  {
    title: "How to wear",
    description:
      "Wash your hands with soap and water for at least 20 seconds or with an alcohol based sanitizer. Make sure the mask covers your nose and mouth, use the nose clip to adjust over your nose.",
  },
  {
    title: "While wearing",
    description:
      "Don’t touch the mask while you’re wearing it and replace it, if damp or wet. Wash your hands before and after replacing. Do not reuse single-use masks.",
  },
  {
    title: "Disposal",
    description:
      "Wash your hands before removing. Then grab the mask by the strings or elastic, not the front, and dispose of in a closed bin. Wash or sanitize your hands.",
  },
]

export const CONTACT_US = "Contact Us"
export const CLICK_BELOW = "Click below to get in touch."
export const CONTACT = "Contact"
