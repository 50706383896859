import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import ValueProps from "../components/valueProps"
import Donate from "../components/donate"
import MaskImage from "../components/maskImage"
import MaskDetails from "../components/maskDetails"
import ContactUs from "../components/contactUs"

const IndexPage = () => {
  const [formActive, setFormActive] = useState(false)

  return (
    <Layout setFormActive={setFormActive}>
      <SEO title="Home" />
      <Hero />
      <ValueProps />
      <Donate />
      <MaskImage />
      <MaskDetails />
      <ContactUs formActive={formActive} setFormActive={setFormActive} />
    </Layout>
  )
}

export default IndexPage
