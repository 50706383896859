import React from "react"
import { Container } from "./componentHelpers"
import styled from "@emotion/styled"
import { theme } from "../theme"
import { MASK_DETAIL_SECTIONS } from "../strings"
import { darken } from "polished"

const BackgroundContainer = styled.div`
  width: 100%;
  background-color: ${darken(0.05, theme.colors.blue)};
  padding: 80px 0;
`

const MaskDetailsContainer = styled(Container)`
  text-align: center;
  position: relative;
  padding: 0 80px;
  h3 {
    color: ${theme.colors.white};
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 20px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  margin: 40px 0;
  div {
    flex-basis: 25%;
    padding: 0 20px;
    border-right: 2px solid ${theme.colors.white};
    color: ${theme.colors.white};
    h4 {
      font-size: 14px;
      letter-spacing: 1px;
      margin: 0 10px 25px;
      font-family: Tahoma, Geneva, Verdana, sans-serif;
    }
    &:last-of-type {
      border: none;
    }
    p {
      font-size: 16px;
      line-height: 1.4;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    div {
      padding: 20px 10px;
      margin: 10px 30px;
      border-bottom: 2px solid ${theme.colors.white};
      border-right: none;
    }
  }
`

const MaskDetails = () => {
  return (
    <BackgroundContainer>
      <MaskDetailsContainer>
        <h3>How To Wear a Face Mask</h3>
        <ContentContainer>
          {MASK_DETAIL_SECTIONS.map(data => {
            return (
              <div>
                <h4>{data.title}</h4>
                <p>{data.description}</p>
              </div>
            )
          })}
        </ContentContainer>
      </MaskDetailsContainer>
    </BackgroundContainer>
  )
}

export default MaskDetails
