import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container } from "./componentHelpers"
import styled from "@emotion/styled"
import { theme } from "../theme"

const MaskImageContainer = styled(Container)`
  margin: 80px auto;
  text-align: center;
  h3 {
    color: ${theme.colors.blue};
    font-size: 22px;
    margin: 10px 0;
    font-family: Tahoma, Geneva, Verdana, sans-serif;
  }
`

const MaskImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "mask_detail.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <MaskImageContainer>
      <h3>What's in our Level-1 Masks</h3>
      <Img fluid={data.placeholderImage.childImageSharp.fluid} />
    </MaskImageContainer>
  )
}

export default MaskImage
