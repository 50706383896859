import React from "react"

import isPropValid from "@emotion/is-prop-valid"
import styled from "@emotion/styled"
import { Container, Button } from "../components/componentHelpers"
import { theme } from "../theme"
import { lighten } from "polished"

const ContactForm = styled.form`
  margin: 20px;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FormGroup = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 100%;
  }
`

const FormLabel = styled.label`
  margin-right: 10px;
  text-align: left;
  color: ${theme.colors.white};
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 4px;
`

const FormInput = styled.input`
  border-radius: 3px;
  background-color: ${lighten(0.2, theme.colors.grey)};
  border: none;
  outline: none;
  color: ${theme.colors.white};
  font-size: 18px;
  padding: 5px;
`

const FormSelect = styled.select`
  border-radius: 3px;
  background-color: ${lighten(0.2, theme.colors.grey)};
  border: none;
  outline: none;
  color: ${theme.colors.white};
  font-size: 18px;
`

const FormTextArea = styled.textarea`
  border-radius: 3px;
  background-color: ${lighten(0.2, theme.colors.grey)};
  border: none;
  outline: none;
  color: ${theme.colors.white};
  font-size: 18px;
  padding: 5px;
`

const SubmitButton = styled(Button)`
  align-self: flex-end;
`

const Contact = () => (
  <Container>
    <ContactForm data-netlify="true" name="contact" method="POST">
      <input type="hidden" name="form-name" value="contact" />
      <FormGroup>
        <FormInput type="text" name="name" required placeholder="Name" />
      </FormGroup>
      <FormGroup>
        <FormInput
          type="tel"
          name="phone_number"
          required
          placeholder="Phone Number"
        />
      </FormGroup>
      <FormGroup>
        <FormInput type="email" name="email" required placeholder="Email" />
      </FormGroup>
      <FormGroup>
        <FormInput type="text" name="company" required placeholder="Company" />
      </FormGroup>
      <FormGroup>
        <FormInput type="text" name="role" required placeholder="Role" />
      </FormGroup>
      <FormGroup>
        <FormLabel>How many masks do you need?</FormLabel>
        <FormSelect name="quantity">
          <option>1-100</option>
          <option>100-1000</option>
          <option>1000-10K</option>
          <option>10K-100K</option>
          <option>>100K</option>
        </FormSelect>
      </FormGroup>
      <FormGroup>
        <FormLabel>What is your timeline?</FormLabel>
        <FormSelect name="timeline">
          <option>3 Weeks</option>
          <option>4 Weeks</option>
          <option>5 Weeks</option>
          <option>6 Weeks</option>
          <option>7 Weeks</option>
          <option>8 Weeks</option>
          <option>9 Weeks</option>
          <option>10 Weeks</option>
          <option>11 Weeks</option>
          <option>12 Weeks</option>
        </FormSelect>
      </FormGroup>
      <FormGroup>
        <FormLabel>Please provide other relevant details:</FormLabel>
        <FormTextArea rows={4} name="other" />
      </FormGroup>
      <FormGroup>
        <SubmitButton type="submit" background={theme.colors.purple}>
          Submit
        </SubmitButton>
      </FormGroup>
    </ContactForm>
  </Container>
)

export default Contact
