import React from "react"
import styled from "@emotion/styled"
import { theme } from "../theme"
import { Container } from "./componentHelpers"
import { HELPING_CANADIANS } from "../strings"

const ContentContainer = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ValuePropHeader = styled.h2`
  color: ${theme.colors.purple};
  font-size: 32px;
  text-align: center;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  @media (max-width: 767px) {
    font-size: 24px;
  }
`

const StyledValueProps = styled.section`
  width: 100%;
  background-color: ${theme.colors.white};
  position: relative;
  display: flex;
  justify-content: space-around;
  padding: 40px 0;
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 20px;
  }
`

const Icon = styled.span`
  font-size: 56px;
  color: ${theme.colors.purple};
`

const ValueProp = styled.div`
  flex-basis: 180px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 16px;
    letter-spacing: 0.3px;
    color: ${theme.colors.grey};
    margin: 10px 0;
    text-align: center;
    @media (max-width: 767px) {
      width: 75%;
    }
  }
`

const VALUE_PROPS = [
  {
    icon: "fab fa-canadian-maple-leaf",
    description:
      "We use Canadian sourced SMS polypropylene and non-woven fabrics in our layered masks",
  },
  {
    icon: "fa fa-award",
    description:
      "We produce high quality masks that meet Canadian health standards",
  },
  {
    icon: "fa fa-calendar-check",
    description:
      "We have over 20 years of experience working with these complex materials",
  },
  {
    icon: "fa fa-shield-alt",
    description: "We provide level 1, 2 and 3 surgical masks, along with N-95",
  },
]

const ValueProps = () => {
  return (
    <Container>
      <ContentContainer id="aboout-us">
        <ValuePropHeader>{HELPING_CANADIANS}</ValuePropHeader>
        <StyledValueProps>
          {VALUE_PROPS.map(data => {
            return (
              <ValueProp>
                <Icon>
                  <i className={`${data.icon}`} />
                </Icon>
                <p>{data.description}</p>
              </ValueProp>
            )
          })}
        </StyledValueProps>
      </ContentContainer>
    </Container>
  )
}

export default ValueProps
