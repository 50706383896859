import React from "react"
import styled from "@emotion/styled"
import { theme } from "../theme"

import { Container, Button } from "./componentHelpers"
import { THANK_YOU_FL_WORKERS, DONATE_TEXT, DONATE_CTA } from "../strings"

const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${theme.colors.lightGrey};
`

const DonateContainer = styled(Container)`
  padding: 80px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.grey};
  h3 {
    font-size: 22px;
    margin: 10px 0;
    font-family: Tahoma, Geneva, Verdana, sans-serif;
  }
  p {
    width: 50%;
    margin: 30px 120px 60px;
    line-height: 1.5;
    font-size: 18px;
    @media (max-width: 767px) {
      width: 90%;
    }
  }
  a {
    display: inline-block;
  }
  @media (max-width: 767px) {
    padding: 40px 0;
  }
`

const DonateButton = styled(Button)`
  color: ${theme.colors.white};
  background-color: ${theme.colors.grey};
  padding: 12px 40px;
  border-radius: 50px;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: bold;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
`

const Donate = () => {
  return (
    <BackgroundContainer>
      <DonateContainer>
        <h3>{THANK_YOU_FL_WORKERS}</h3>
        <p>{DONATE_TEXT}</p>
        <a
          target="_blank"
          href="https://www.canadahelps.org/en/donate-to-covid-19-hospital-and-healthcare-charities/"
        >
          <DonateButton background={theme.colors.grey}>
            {DONATE_CTA}
          </DonateButton>
        </a>
      </DonateContainer>
    </BackgroundContainer>
  )
}

export default Donate
