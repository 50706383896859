import React, { useState } from "react"
import styled from "@emotion/styled"
import { theme } from "../theme"

import { Container, Button } from "./componentHelpers"
import { CONTACT, CONTACT_US } from "../strings"
import ContactForm from "../components/contactForm"

const Wrapper = styled.div`
  background-color: ${theme.colors.grey};
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  text-align: center;
  h2 {
    font-size: 36px;
    margin: 15px 0;
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    color: ${theme.colors.white};
  }
  p {
    width: 50%;
    color: ${theme.colors.white};
    font-size: 20px;
    line-height: 1.4;
    margin: 15px 0 30px;
    @media (max-width: 767px) {
      width: auto;
    }
  }
`

const ContactButton = styled(Button, {
  shouldForwardProp: prop => true,
})`
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
`

const FormContainer = styled.div<{ active: boolean }>`
  margin: 20px 0 0;
  display: block;
  max-height: ${props => (props.active ? 1000 : 0)}px;
  overflow: hidden;
  transition: 1s all ease;
`

const ContactUs = ({ formActive, setFormActive }) => {
  return (
    <Wrapper>
      <Container id="contact">
        <ContentContainer>
          <h2>{CONTACT_US}</h2>
          <p>
            Get in touch to learn more about how we can help protect you and
            your employees.
          </p>
          {!formActive && (
            <ContactButton
              background={theme.colors.purple}
              onClick={() => setFormActive(!formActive)}
            >
              {CONTACT}
            </ContactButton>
          )}
          <FormContainer active={formActive}>
            <ContactForm />
          </FormContainer>
        </ContentContainer>
      </Container>
    </Wrapper>
  )
}

export default ContactUs
